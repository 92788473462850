<template>
    <v-container fluid style="padding-bottom: 50px;">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-text class="p-2">
                        <v-container fluid>
                            <v-row>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-menu
                                        ref="modal"
                                        v-model="modal"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            outlined
                                            dense
                                            v-model="date_from"
                                            label="Last Period"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            type="month"
                                            v-model="date_from"
                                            no-title
                                            @input="modal = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building"
                                        clearable
                                        dense
                                        solo
                                        v-model="region"
                                        :items="regions"
                                        item-value="region"
                                        :item-text="item => item.region.trim()"
                                        label="Region"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        @change="(event) => getOffice(event)"
                                        :disabled="region_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building-outline"
                                        clearable
                                        dense
                                        solo
                                        v-model="office"
                                        :items="offices"
                                        item-value="office_id"
                                        :item-text="item => item.office.trim()"
                                        label="Office"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_office"
                                        @change="(event) => getSales(event)"
                                        :disabled="office_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="3" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-clipboard-account"
                                        clearable
                                        dense
                                        solo
                                        v-model="sales"
                                        :items="saless"
                                        item-value="sales_id"
                                        :item-text="item => item.nama"
                                        label="Sales"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_sales"
                                        @change="(event) => getCustomer(event)"
                                        :disabled="sales_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="3" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-account-tie"
                                        clearable
                                        dense
                                        solo
                                        v-model="customer"
                                        :items="customers"
                                        item-value="customer_id"
                                        :item-text="item => item.nama"
                                        label="Customer"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_customer"
                                        @change="(event) => getConsignee(event)"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="4" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-map-marker"
                                        clearable
                                        dense
                                        solo
                                        v-model="consigne"
                                        :items="consignes"
                                        item-value="consignee_id"
                                        :item-text="item => item.cons_name + ' | ' + item.address1"
                                        label="Consignee"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_consigne"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="6">
                                    <v-btn class="border-12 mr-2"  style="padding: 20px;"
                                    elevation="2" small @click="clear()">Clear</v-btn>
                                    <v-btn class="border-12" style="padding: 20px;"
                                    color="info" elevation="2" small @click="searchFunction()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-12">
            <v-col class="col-12" sm="12" md="12">
                <v-card outlined class="p-0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="headers"
                                    :items="histories"
                                    class="elevation-1"
                                    :items-per-page="30"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="$store.state.overlay"
                                    height="425"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchItemTrans" 
                                    :item-class="tr_datatable"
                                    group-by="cust_nama"
                                >    
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List History </h6>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                <v-btn
                                                small
                                                color="#005c37"
                                                class="py-5 mr-3 text-white border-12"
                                                >
                                                    <v-icon>mdi-file-excel</v-icon>
                                                    <download-excel
                                                        class="text"
                                                        :fetch           = "exportExcel"
                                                        :data="histori_exports"
                                                        :before-generate = "startDownload"
                                                        :before-finish   = "finishDownload">
                                                        Export Excel
                                                    </download-excel>
                                                </v-btn>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.alamat`]="{ item }">
                                        {{ item.cons_name }} - {{ item.address1 }}
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            search:'',
            breadcumbs: [
                {
                    text: 'Kencana Baja Trada',
                    disabled: false,
                    href: '/admin/kbt',
                },
                {
                    text: 'Order',
                    disabled: true,
                },
                {
                    text: 'Customer History',
                    disabled: true,
                }
            ],
            regions: [],
            region: '',
            offices: [],
            office: '',
            saless: [],
            sales: '',
            customers: [],
            customer: '',
            consignes: [],
            consigne: '',
            divisions: [
                {
                    value: 'all',
                    text: 'All'
                },
                {
                    value: 'RETAIL',
                    text: 'RETAIL'
                },
                {
                    value: 'PROYEK',
                    text: 'PROYEK'
                }
            ],
            division: 'all',
            modal: false,
            date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
            searchItemTrans: '',
            histories: [],
            headers: [],
            headersColumn: {},
            months: [],
            loading_office: false,
            loading_sales: false,
            loading_customer: false,
            loading_consigne: false,
            region_disabled: false,
            office_disabled: false,
            sales_disabled: false,
            division_disabled: false,
            searchCustomer: null,
            histori_exports: [],
            region_proyek: '',
            region_proyeks:[],
            region_proyek_disabled: false
        }
    },
    computed:{
    },
    watch: {
        // searchCustomer (val) {
        //     val && val !== this.customer && this.getCustomer(val)
        // },
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)  
        this.getMonth(this.date_from, 12)
        await this.getEnvConf()
        await this.getRegion()
        // await this.getOffice('KMB')
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        tr_datatable(item) {
            var rowClass = 'tr_datatable_history'
            return rowClass;
        },
        clear(){
            if (this.region_disabled == false) {
                this.region = ''
            }
            if (this.office_disabled == false) {
                this.office = ''
            }
            if (this.sales_disabled == false) {
                this.sales = ''
            }
            if (this.division_disabled == false) {
                this.division = ''
            }
            this.customer = ''
            this.consigne = ''
        },
        async getEnvConf(){

            const respDataRegion = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KBT&appl_id=WEBKBT&var_id=REGION&groupid=${this.$store.state.kbt.group_id}`, null, false, false, false)  
            
            if (respDataRegion.status == 200) {
                this.region = respDataRegion.data.data ? respDataRegion.data.data : ''
                this.region_disabled = respDataRegion.data.data ? true : false
                if (this.region_disabled) {
                    this.region_proyek_disabled = true
                    await this.getRegion(this.region)
                    await this.getOffice(this.region)
                }
            }

            const respDataOffice = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KBT&appl_id=WEBKBT&var_id=BRANCH&groupid=${this.$store.state.kbt.group_id}`, null, false, false, false)  
            
            if (respDataOffice.status == 200) {
                this.office = respDataOffice.data.data ? respDataOffice.data.data : ''
                this.office_disabled = respDataOffice.data.data ? true : false
                if (this.office_disabled) {
                    this.region_proyek_disabled = true
                    this.region_disabled = true
                    await this.getRegion(this.office)
                    await this.getOffice(this.office)
                    await this.getSales(this.office)
                } else {
                    await this.getOffice('KBT')
                }
            }

            const respDataSales = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KBT&appl_id=WEBKBT&var_id=SALESID&groupid=${this.$store.state.kbt.group_id}`, null, false, false, false)  
            
            if (respDataSales.status == 200) {               
                if (respDataSales.data.data) {
                    if (this.$store.state.kbt.group_id == 'RM KBT' || this.$store.state.kbt.group_id == 'KACAB KBT') {
                        this.sales_disabled = false
                    } else {
                        this.sales = respDataSales.data.data ? respDataSales.data.data : ''
                        this.sales_disabled = respDataSales.data.data ? true : false

                        if (this.sales_disabled) {
                            this.region_proyek_disabled = true
                            this.region_disabled = true
                            this.office_disabled = true
                            await this.getSales(this.sales)
                            await this.getCustomer(this.sales)
                        }
                    }
                    
                }
            }
        },
        async getRegion(event){
            await axios.get(`${process.env.VUE_APP_URL}/v2/master/kbt/region?region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.regions = res.data
                if (this.region_disabled && res.data.length > 0) {
                    this.region = res.data[0].region
                    this.region_disabled = true
                } else {
                    this.region = ''
                    this.region_disabled = false
                }
                
            });
        },
        async getOffice(event){
            if (event == '' || event == null) {
                this.office = ''
                this.region = ''
            } else {
                this.loading_office = true
                this.offices = []
                await axios.get(`${process.env.VUE_APP_URL}/v2/master/kbt/office?region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_office = false
                    this.offices = res.data
                });
            }
        },
        async getSales(event){
            if (event == '' || event == null) {
                this.office = ''
                this.sales = ''
            } else {
                this.loading_sales = true

                if (this.$store.state.kbt.group_id == 'RM KBT' || this.$store.state.kbt.group_id == 'KACAB KBT') {
                    var salesId = ''
                } else {
                    var salesId = this.sales ? this.sales : ''
                }

                await axios.get(`${process.env.VUE_APP_URL}/v2/master/kbt/sales?office_id=${this.office ? this.office : ''}&sales_id=${salesId}&active_flag=Y`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_sales = false
                    this.saless = res.data
                    if (this.sales_disabled && res.data.length > 0) {
                        this.office = res.data[0].office_id
                        this.office_disabled = true
                        this.getRegion(this.office)
                        this.getOffice(this.office)
                    }
                });
                
            }
        },
        async getCustomer(event){
            if (event == '' || event == null) {
                this.sales = ''
                this.customer = ''
            } else {
                this.loading_customer = true
                await axios.get(`${process.env.VUE_APP_URL}/v2/master/kbt/customer?customer_id=${this.customer ? this.customer : ''}&sales_id=${this.sales ? this.sales : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_customer = false
                    this.customers = res.data
                });
            }
        },
        async getConsignee(event){
            if (event == '' || event == null) {
                this.customer = ''
                this.consigne = ''
            } else {
                this.loading_consigne = true
                await axios.get(`${process.env.VUE_APP_URL}/v2/master/kbt/consignee?customer_id=${this.customer ? this.customer : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_consigne = false
                    this.consignes = res.data
                });
            }
        },
        getMonth(start, value){
            this.months = []
            this.headers = []
            const monthString = ["January","February","March","April","May","June","July","August","September","October","November","December"];
            for (let index = 0; index <= value; index++) {
                if (index == 0) {
                    var startDate = new Date( Date.parse(start) - (new Date()).getTimezoneOffset() * 60000)
                    let name = monthString[startDate.getMonth()];
                    this.months.push({
                        value: start.replace(/-/g,""),
                        text: start
                    })
                } else {
                    var startDate = new Date( Date.parse(start) - (new Date()).getTimezoneOffset() * 60000)
                    var startDayofMonth = new Date(startDate.getFullYear(), (startDate.getMonth() + 1) - index, 0);
                    // console.log(startDayofMonth);
                    var month = (new Date(startDayofMonth - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
                    let name = monthString[(new Date(startDayofMonth - (new Date()).getTimezoneOffset() * 60000)).getMonth()];

                    this.months.push(
                        {
                            value: month.replace(/-/g,""),
                            text: month
                        }    
                    )
                }                  

            }

            this.headers = [
                { text: 'Customer', value: 'cust_nama', width:300, align: 'left' },
                { text: 'Consignee', value: 'alamat', width:300, align: 'left', groupable:false, class: 'my-border' },
                { text: this.months[0].text, value: this.months[0].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[1].text, value: this.months[1].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[2].text, value: this.months[2].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[3].text, value: this.months[3].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[4].text, value: this.months[4].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[5].text, value: this.months[5].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[6].text, value: this.months[6].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[7].text, value: this.months[7].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[8].text, value: this.months[8].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[9].text, value: this.months[9].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[10].text, value: this.months[10].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[11].text, value: this.months[11].value, width:100, align: 'right', groupable:false, class: 'my-border' }
            ]
        },
        async searchFunction(){
            this.$store.dispatch('setOverlay', true)  

            // if (this.region === '' || this.office === '') {
            //     this.snackbar = {
            //         color: "error",
            //         icon: "mdi-alert-circle",
            //         mode: "multi-line",
            //         position: "top",
            //         timeout: 7500,
            //         title: "Error",
            //         text: 'Please Fill Region, Office',
            //         visible: true
            //     };

            //     this.$store.dispatch('setOverlay', false)  
            //     return false
            // }

            this.getMonth(this.date_from, 12)

            var reqBody = {
                'region': this.region ? this.region : '',
                'office_id': this.office ? this.office : '',
                'SalesId': this.sales ? this.sales : '',
                'CustomerId': this.customer ? this.customer : '',
                'startPeriod': this.months.length > 0 ? this.months[11].value : '',
                'endPeriod': this.months.length > 0 ? this.months[0].value : '',
                'months' : this.months
            }

            const respData = await backendApi.fetchCore('/v2/kbt/order-by-consignee', reqBody, false, false, false)
            if (respData.status === 200) {
                this.histories = respData.data
                this.$store.dispatch('setOverlay', false)  
            }

        },
        async exportExcel(){
            // if (this.region === '' || this.office === '') {
            //     this.snackbar = {
            //         color: "error",
            //         icon: "mdi-alert-circle",
            //         mode: "multi-line",
            //         position: "top",
            //         timeout: 7500,
            //         title: "Error",
            //         text: 'Please Fill Region, Office',
            //         visible: true
            //     };

            //     this.$store.dispatch('setOverlay', false)  
            //     return false
            // }
            
            this.getMonth(this.date_from, 12)

            var reqBody = {
                'region': this.region ? this.region : '',
                'office_id': this.office ? this.office : '',
                'SalesId': this.sales ? this.sales : '',
                'CustomerId': this.customer ? this.customer : '',
                'startPeriod': this.months.length > 0 ? this.months[11].value : '',
                'endPeriod': this.months.length > 0 ? this.months[0].value : '',
                'months' : this.months
            }

            const respData = await backendApi.fetchCore('/v2/kbt/order-by-consignee', reqBody, false, false, false)
            if (respData.status === 200) {
                this.histori_exports = respData.data

                return this.histori_exports
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },    
}
</script>